.nav-top__links {
  @apply md:flex md:relative md:justify-end md:z-0 hidden;
}

#nav-top__toggle:checked ~ .nav-top__links {
  @apply block bg-white absolute mt-4 border-b-2 border-t-2 border-mid-a right-0 px-10 py-4 z-10 leading-10 w-full text-center;
  transform: translateX(0);
  animation: slidein 300ms;
}
#nav-top__toggle ~ .nav-top__links .nav-top__item {
  @apply md:my-1 my-5;
}
.nav-top__toggle {
  @apply hidden;
}

.nav-top__hamburger {
  @apply md:hidden block p-1;
}
.hamburger__line {
  @apply block my-1 h-1 w-8 bg-dark-b;
}
.nav-top__item {
  @apply flex-col font-medium block mx-5 text-dark-a;
}

.nav-top__item:hover {
  @apply text-mid-b;
}

@keyframes slidein {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
