@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  
  @import '@fonts/Montserrat/font-face';

  * {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }
}