p {
  @apply m-5;
}

#layout-container {
  @apply bg-dark-a;
}

.layout-content {
  @apply sm:w-full py-3 text-white;
  @apply xl:w-10/12 m-auto md:px-5;
}
