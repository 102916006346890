h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bold text-white;
}

h1.heading {
  @apply md:text-5xl text-4xl;
}

h2.heading {
  @apply md:text-3xl text-2xl;
}

h3.heading {
  @apply text-xl;
}

h4.heading {
  @apply text-lg;
}

h5.heading {
  @apply text-base;
}

h6.heading {
  @apply text-sm;
}
